var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pageLoading)?_c('v-container',{staticClass:"bg-color-theme px-3"},[_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line, card"}})],1):_c('v-container',{staticClass:"bg-color-theme"},[_c('v-card',{staticClass:"mt-2"},[_c('v-card-title',{staticClass:"justify-center font-18px fw-500 text-333"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.getBusinessYears.start_date,"YYYY"))+"年度 ")]),_c('v-card-subtitle',{staticClass:"text-center text-87 font-14px mb-0"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.getBusinessYears.start_date,"YYYY/MM"))+" ~ "+_vm._s(_vm._f("formatDate")(_vm.getBusinessYears.end_date,"YYYY/MM"))+" ")]),_c('div',{staticClass:"border-after-date"}),_c('div',{staticClass:"d-flex justify-center align-center mt-5 light-yellow mx-3 height-81"},[_c('div',{staticClass:"font-12px fw-500"},[_c('v-avatar',{attrs:{"color":"white"}},[_c('span',[_vm._v("参考値")])])],1),_c('div',{staticClass:"text-center ml-3"},[_c('div',{staticClass:"mb-0 font-15px"},[_vm._v(" 前年度売上実績 ")]),_c('div',{staticClass:"font-25px fw-500 mt-n2"},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(_vm.getGoalLastAnnualSale))+" ")])])]),_c('div',{staticClass:"ml-3 mt-5 font-13px text-555"},[_vm._v(" ① 年次売上目標を入力して下さい。 ")]),_c('validation-observer',{ref:'observer',scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",attrs:{"lazy-validation":"","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"rules":"enter_half_width_amount","name":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputCurrency',{staticClass:"annual-goal-field mx-3 mt-3 pb-4",attrs:{"background-color":"#EAEDF0","dense":"","solo":"","height":62,"error-messages":errors},on:{"input":_vm.fillMonthlyGoalByDistributionFactors},model:{value:(_vm.annualGoal.value),callback:function ($$v) {_vm.$set(_vm.annualGoal, "value", $$v)},expression:"annualGoal.value"}})]}}],null,true)}),_c('div',{staticClass:"d-flex justify-end mx-3 mt-n9"},[_c('v-btn',{staticClass:"text-888",attrs:{"text":"","small":""},on:{"click":_vm.resetForm}},[_vm._v("クリア "),_c('v-icon',{attrs:{"right":"","dark":"","x-small":""}},[_vm._v("$reload ")])],1)],1),_c('div',{staticClass:"d-flex justify-center mt-n1"},[_c('v-icon',{attrs:{"size":"12"}},[_vm._v("$grayDownArrowClosed")])],1),_c('div',{staticClass:"d-flex justify-space-between mx-3 mt-5"},[_c('div',{staticClass:"font-13px text-555"},[_vm._v(" ② 月次売上目標を調整して更新して下さい。 ")]),_c('v-icon',{staticClass:"mt-1",attrs:{"size":"12"},on:{"click":function($event){return _vm.helpModal('GoalsSettingModal')}}},[_vm._v("$infoIconGrey")])],1),_c('div',{staticClass:"business-days-table mt-5 mx-3"},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th'),_c('th',{staticClass:"text-center"},[_vm._v("月次目標")]),_c('th',{staticClass:"text-center"},[_vm._v("実績売上"),_c('br'),_vm._v("達成率")])])]),_vm._l((_vm.getGoalByYear),function(goalYears,j){return _c('tbody',{key:j},_vm._l((goalYears),function(item,k){return _c('tr',{key:k},[_c('td',{staticClass:"fw-500 font-14px",staticStyle:{"width":"20%"}},[_vm._v(" "+_vm._s(item.month)+" ")]),_c('td',[_c('v-row',{attrs:{"align":"center"}},[_c('validation-provider',{attrs:{"rules":"enter_half_width_amount","name":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputCurrency',{staticClass:"monthly-goal-field pt-6",class:true ? 'blue-text' : 'gray-text',attrs:{"background-color":"#F5F5F5","dense":"","solo":"","error-messages":errors,"placeholder":"00"},on:{"keyup":_vm.calculateAnnualGoal},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", _vm._n($$v))},expression:"item.value"}})]}}],null,true)})],1)],1),_c('td',{staticClass:"text-center font-14px",staticStyle:{"width":"33%"}},[_c('div',[_vm._v(" "+_vm._s(_vm.priceFormat(item.sales))+" ")]),(_vm.getAchievementRate(item.value, item.sales) > 0)?_c('div',{class:_vm.getAchievementRate(item.value, item.sales) >= 100
                        ? 'green-text'
                        : 'red-text'},[_vm._v(" "+_vm._s(_vm.getAchievementRate(item.value, item.sales).toFixed(1))+"% 達成 ")]):_c('div',{staticClass:"text-777"},[_vm._v(" "+_vm._s(_vm.getAchievementRate(item.value, item.sales).toFixed(1))+"% 達成 ")])])])}),0)})],2)],1),_c('div',{staticClass:"mx-3 py-6"},[_c('v-btn',{staticClass:" font-14px fw-700",attrs:{"color":"primary","loading":_vm.getApiProcessingStatus,"block":"","disabled":invalid},on:{"click":_vm.submit}},[_vm._v(" 保存する ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }