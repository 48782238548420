<template>
  <v-container v-if="pageLoading" class="bg-color-theme px-3">
    <v-skeleton-loader type="list-item-two-line, card"></v-skeleton-loader>
  </v-container>

  <v-container v-else class="bg-color-theme px-3 fixed-cost-main">
    <v-card>
      <v-card-title class="text-center fw-400 font-18px justify-center py-3">{{
        yearMonth
      }}</v-card-title>
      <v-divider></v-divider>

      <div class="px-4 py-8 d-flex justify-space-between align-center">
        <div class="text-center">
          <span class="apply-label rounded px-2 font-12px">
            前月の固定費を適用
          </span>
          <span class="ml-2 pt-0 mt-0">
            <v-btn
              color="primary"
              class="fw-400 font-12px"
              elevation="0"
              x-small
              @click="previousFixedCost"
              >実行</v-btn
            >
          </span>
        </div>

        <div>
          <v-btn text small class="text-888" @click="reset"
            >クリア
            <v-icon right dark x-small>$reload </v-icon>
          </v-btn>
        </div>
      </div>

      <validation-observer :ref="'observer'" v-slot="{ invalid }">
        <v-form
          @submit.prevent="submit"
          lazy-validation
          autocomplete="off"
          ref="form"
        >
          <div v-for="(field, i) in fields" :key="field.name">
            <template v-if="field.type == 'text'">
              <v-row class="ma-0 text-no-wrap px-8">
                <v-col
                  class="pa-0 flex-grow-1 flex-shrink-0 full-width"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="field.rules"
                    :name="field.name"
                  >
                    <v-label>{{ field.label }} </v-label>

                    <v-text-field
                      :prefix="field.prefix"
                      :placeholder="field.placeholder"
                      background-color="#EAEDF0"
                      dense
                      solo
                      v-model="field.value"
                      :error-messages="errors"
                      autocomplete="chrome-off"
                      :hint="field.hint"
                      persistent-hint
                      :type="field.nativeType"
                      class="cost-field"
                      inputmode="number"
                      @keyup="getPriceFormat(field.value, i)"
                    >
                      <template v-slot:append-outer>
                        <v-icon
                          :class="!field.infoIcon ? 'visibility-hidden' : ''"
                          small
                          role="button"
                          @click="helpModal(field.modalComponent)"
                          class="ml-1 mt-1"
                          >$infoIconGrey</v-icon
                        >
                      </template>
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </template>
          </div>

          <v-row class="ma-0 mt-3 px-4 pb-7">
            <v-col
              class="px-0 pt-0 pb-0 d-flex justify-space-between"
              cols="12"
              md="12"
            >
              <v-btn
                type="button"
                color="white"
                class="primary--text border-primary"
                min-width="146"
                @click.stop="$router.go(-1)"
              >
                キャンセル
              </v-btn>
              <v-btn
                type="submit"
                color="primary"
                min-width="146"
                :loading="loading"
                :disabled="invalid"
              >
                保存する
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CostInput",
  data() {
    return {
      loading: false,
      disabled: false,
      errors: null,
      invalid: null,
      pageLoading: false,
      yearMonth: null,
      fields: [
        {
          prefix: "人件費(正社員)",
          name: "labor_cost_regular_employees",
          type: "text",
          placeholder: "￥1,000,000",
          value: "",
          rules: "required:人件費(正社員)|enter_half_width_amount"
        },
        {
          prefix: "家賃",
          name: "rent",
          type: "text",
          placeholder: "￥1,000,000",
          value: "",
          rules: "required:家賃|enter_half_width_amount"
        },
        {
          prefix: "プラットフォーム手数料",
          name: "platform_fee",
          type: "text",
          placeholder: "￥1,000,000",
          value: "",
          rules: "required:プラットフォーム手数料|enter_half_width_amount",
          infoIcon: true,
          modalComponent: "PlatformFeeModal"
        },
        {
          prefix: "その他",
          name: "others",
          type: "text",
          placeholder: "￥1,000,000",
          value: "",
          rules: "required:その他|enter_half_width_amount",
          infoIcon: true,
          modalComponent: "OtherCostModal"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getFixedcostByYearMonth", "getPreviousFixedCost"])
  },
  created() {
    this.yearMonth = this.$route.query.year_month;
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      let param = {
        shop_id: this.$store.getters.getFirstShopObject.id, //Shop Id
        year_month: this.yearMonth
      };

      this.$store.dispatch("FIXEDCOST_GET_BY_YEAR_MONTH", param).finally(() => {
        this.pageLoading = false;
        this.patchEditFormValues();
      });
    },
    patchEditFormValues() {
      this.fields.forEach(field => {
        if (this.getFixedcostByYearMonth.length > 0) {
          field.value = this.getPriceFormat1(
            this.getFixedcostByYearMonth[0]?.[field.name]
          );
        } else {
          field.value = "";
        }
      });
    },
    patchPreviousFormValues() {
      this.fields.forEach(field => {
        if (this.getPreviousFixedCost.length == 0) {
          field.value = "";
        } else {
          field.value = this.getPriceFormat1(
            this.getPreviousFixedCost?.[field.name]
          );
        }
      });
    },
    getPriceFormat(amount, index) {
      if (amount != "") {
        this.fields[index].value = new Intl.NumberFormat("ja-JP", {
          style: "currency",
          currency: "JPY"
        }).format(amount.replace(/[^A-Z0-9]/gi, ""));
        this.fields[index].value =
          this.fields[index].value == "￥NaN"
            ? amount
            : this.fields[index].value;
      } else {
        this.fields[index].value = "";
      }
      return this.fields[index].value;
    },
    getPriceFormat1(amount) {
      return this.priceFormat(amount);
    },
    helpModal(component) {
      this.$store.commit("showModal", { component });
    },
    reset() {
      this.$refs.form.reset();
    },
    previousFixedCost() {
      let param = {
        shop_id: this.$store.getters.getFirstShopObject.id, //Shop Id
        year_month: this.yearMonth
      };

      this.$store
        .dispatch("FIXEDCOST_GET_PREVIOUS_MONTH", param)
        .finally(() => {
          this.pageLoading = false;
          this.patchPreviousFormValues();
        });
    },
    submit() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }

        let params = {};
        let shop_id = this.$store.getters.getFirstShopObject.id;
        this.fields.forEach(field => {
          if (field) {
            if (field.value) {
              params[field.name] = field.value.replace(/[^A-Z0-9]/gi, "");
            }
          }
        });
        if (this.getFixedcostByYearMonth.length > 0) {
          let fixed_cost_id = this.getFixedcostByYearMonth[0].id;
          this.$store
            .dispatch("FIXEDCOST_UPDATE", { params, shop_id, fixed_cost_id })
            .then(response => {
              if (response.status === 200) {
                this.$router.push({ name: "fixedCostList" });
                this.$store.commit("showAlert", {
                  text: "編集内容を保存しました。",
                  successStatus: "info"
                });
              }
            });
        } else {
          params["year_month"] = this.yearMonth;
          this.$store
            .dispatch("FIXEDCOST_REGISTER", { params, shop_id })
            .then(response => {
              if (response.status === 200) {
                this.$router.push({ name: "fixedCostList" });
                this.$store.commit("showAlert", {
                  text: "編集内容を保存しました。",
                  successStatus: "info"
                });
              }
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" src="./style.scss"></style>
