<template>
  <v-card class="pb-2 report mb-6">
    <div class="full-width header">
      <p class="pt-4 pl-4">{{ todayDate }}</p>
      <div class="mt-3 d-flex justify-center">
        <div class="dropdown">
          <v-select
            v-model="year"
            dense
            solo
            background-color="#eaedf0"
            class="year-select"
            label="Year"
            :items="options"
            item-text="label"
            item-value="value"
            @change="handelYearChange"
          >
            <template v-slot:selection="{ item }">
              <span class="d-flex position-absolute justify-center year-text">
                {{ item.label }}
              </span>
            </template>
          </v-select>
        </div>
      </div>
    </div>

    <DataTable
      :headers="headers"
      :items="reportItems"
      :total-records="12"
      :number-of-pages="1"
      :items-per-page="12"
      :disablePagination="true"
      class="mx-2 monthly-report "
      :mobile-breakpoint="0"
    >
      <template v-slot:item="{ item, index }">
        <tr>
          <td class="px-1" :style="{ 'border-bottom': lastItemCheck(index) }">
            <p class="headings">{{ item.month + "月" }}</p>
          </td>
          <td
            class="text-center"
            :style="{ 'border-bottom': lastItemCheck(index) }"
          >
            <p class="headings" v-if="item.sales">
              {{ item.sales | formatMoney }}
            </p>
            <p class="headings" v-else>￥--</p>
          </td>
          <td
            class="text-center px-1"
            :style="{ 'border-bottom': lastItemCheck(index) }"
          >
            <p class="headings" v-if="item.number_of_total_customer">
              {{ item.number_of_total_customer | formatNumber }}
            </p>
            <p class="headings" v-else>--</p>
          </td>
          <td
            class=" text-center px-1"
            :style="{ 'border-bottom': lastItemCheck(index) }"
          >
            <p class="headings" v-if="item.average_customer_spend">
              {{ item.average_customer_spend | formatMoney }}
            </p>
            <p class="headings" v-else>￥--</p>
          </td>
          <td class="" :style="{ 'border-bottom': lastItemCheck(index) }">
            <div class="headings text-center d-flex align-center">
              <p class="pr-2" v-if="item.average_in_shop_customer_spend">
                {{ item.average_in_shop_customer_spend | formatMoney }}
              </p>
              <p class="pr-2" v-else>￥--</p>
              <v-btn
                class="ml-auto pa-0"
                small
                rounded
                min-width="20"
                width="20"
                height="20"
                text
                :to="{
                  name: 'monthlyReport',
                  params: { date: `${year}-${item.month}` }
                }"
              >
                <v-icon x-small>$goNext</v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
      </template>
    </DataTable>
    <v-row
      v-if="summary"
      no-gutters
      class="summary font-weight-bold mx-2 mt-3 mb-3 pr-6"
    >
      <v-col class="d-flex align-center justify-center">累計</v-col>
      <v-col class="d-flex align-center justify-center">{{
        summary.sales | formatMoney
      }}</v-col>
      <v-col class="d-flex align-center justify-center">{{
        summary.number_of_total_customer | formatNumber
      }}</v-col>
      <v-col class="d-flex align-center justify-center">{{
        summary.average_customer_spend | formatMoney
      }}</v-col>
      <v-col class="d-flex align-center justify-center">{{
        summary.average_in_shop_customer_spend | formatMoney
      }}</v-col>
    </v-row>
  </v-card>
</template>
<script>
import DataTable from "@/components/ui/DataTable";
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";

export default {
  components: {
    DataTable
  },
  data() {
    return {
      todayDate: `${dayjs().format("YYYY-MM-DD")}`,
      year: "",
      options: [],
      headers: [
        {
          text: "",
          value: "month",
          sortable: false,
          width: "40px"
        },
        {
          text: "売上",
          align: "center",
          value: "sales",
          class: ["px-2"],
          sortable: false
        },
        {
          text: "客数",
          value: "number_of_total_customer",
          class: ["pl-2", "pr-1"],
          sortable: false,
          align: "center",
          width: "40px"
        },
        {
          text: "客単価",
          value: "average_customer_spend",
          class: ["pr-2", "pl-1"],
          align: "center",
          width: "20%",
          sortable: false
        },
        {
          text: "店内客単価",
          align: "left",
          value: "average_in_shop_customer_spend",
          class: ["pl-2"],
          width: "85px",
          sortable: false
        }
      ]
    };
  },
  computed: {
    pageLoading() {
      return !this.$store.state.report.status;
    },
    ...mapGetters(["getFirstShopObject", "getYearlyReportList"]),
    reportItems() {
      return this.getYearlyReportList?.reports;
    },

    summary() {
      return this.getYearlyReportList?.summary;
    },
    shopId() {
      return this.getFirstShopObject?.id;
    }
  },
  created() {
    this.getYears();
    this.fetchMonthlyReport();

    let this_this = this;
    this.$root.$off("shopChanged");
    this.$root.$on("shopChanged", function() {
      this_this.fetchMonthlyReport();
    });
  },
  methods: {
    lastItemCheck(index) {
      if (index !== this.reportItems.length - 1) {
        return "0.5px dashed #c9c9c9 !important";
      } else if (index == this.reportItems.length - 1) {
        return "0.5px solid #c9c9c9 !important";
      }
    },
    getYears() {
      const currentYear = (this.year = new Date().getFullYear());
      const previousYear = currentYear - 1;
      this.options.push(
        { label: previousYear + "年", value: previousYear },
        { label: currentYear + "年", value: currentYear }
      );
    },
    async fetchMonthlyReport() {
      await this.$store.dispatch("GET_YEARLY_REPORT", {
        shop_id: this.shopId,
        year: this.year,
        type: "year"
      });
    },
    async handelYearChange() {
      await this.fetchMonthlyReport();
    }
  }
};
</script>

<style scoped>
.header {
  height: 100px;
  border-bottom: 1px solid #d9d9d9;
}
p {
  font-size: 12px;
  color: #818181;
  margin: 0;
}
.year-select .v-text-field__prefix,
.cashbook-field .v-text-field__prefix {
  color: #bdbdbd !important;
  font-size: 10px !important;
}

.year-select input,
.cashbook-field input {
  text-align: center !important;
  font-size: 14px !important;
}
.year-select.v-input {
  font-size: 14px !important;
  color: #333 !important;
}
.year-text {
  width: 100% !important;
}

.dropdown {
  width: 176px;
  height: 25px;
  background: #eaedf0;
  border-radius: 3px;
  position: relative;
}
.summary {
  background: #f6f9ff;
  border-radius: 3px;
  height: 40px;
  font-size: 10px;
  color: #495bc4;
}
.headings {
  font-size: 12px;
}
.monthly-report ::v-deep th {
  border-bottom: 1px dashed #d9d9d9 !important;
}
.report {
  width: 345px;
}
</style>
