<template>
  <div class="d-flex flex-column align-center">
    <div class="d-flex mb-6 full-width justify-center align-center">
      <p class="section">重要な経営数値</p>
    </div>
    <Sheet class="mb-4" header="最新の日報" count="1" footer=" 日報一覧を見る ">
      <BillDescription
        color="yellow-style"
        dash="yellow-dash"
        button="yellow-button"
        startDate="2021-09-18"
        endDate="2021-09-17"
        :icons="['$sunny', '$cloudy']"
      ></BillDescription>
      <div
        class="d-flex mt-3 mb-5 section-font-color justify-center align-center"
      >
        店休日
      </div>
      <BillDescription
        color="yellow-style"
        dash="yellow-dash"
        button="yellow-button"
        startDate="2021-09-18"
        endDate="2021-09-17"
        :icons="['$sunny', '$cloudy']"
      ></BillDescription>
    </Sheet>

    <Sheet
      header="月報 : 9月の売上・コスト"
      count="2"
      footer=" 月報一覧を見る "
    >
      <BillDescription
        color="primary-style"
        dash="primary-dash"
        button="primary-button"
      ></BillDescription>
      <div class="d-flex mb-9 flex-column justify-center align-center">
        <v-btn class=" mt-8 mb-8" height="36" width="248" color="primary">
          売上
        </v-btn>
        <div class="font-16px section-font-color">9月の売上データ</div>
      </div>
      <MealCards title="Lunch" icon="$lunch"></MealCards>
      <MealCards title="Dinner" icon="$dinner"></MealCards>
      <div class="d-flex justify-center">
        <div class="mt-12 mb-6 font-16px section-font-color">
          売上の推移
        </div>
      </div>
      <MonthlySales></MonthlySales>
      <div class="d-flex mt-15 mb-10 flex-column justify-center align-center">
        <v-btn class=" btn" color="primary">
          売上
        </v-btn>
      </div>
      <!-- sales cost composition ratio -->
      <DoughnutChart
        :showLabels="true"
        v-bind="{ chartData, backgroundColors, labels }"
      >
        <template v-slot:title>
          <p class="title mb-3">9月の対売上コスト構成比</p>
          <p class="font-weigt-thin subtitle mb-9">9月1日～9月18日</p>
        </template>
        <template v-slot:inner-text>
          <div class="font-12px font-weight-bold">累計売上</div>
          <div class="font-14px font-weight-bold">5,616,312</div>
        </template>
        <template v-slot:result>
          <p class="mb-6 mt-6">
            累計コスト
            <span class="font-weight-bold">￥3,549,364</span>
          </p>
        </template>
      </DoughnutChart>
    </Sheet>
    <v-container>
      <v-row>
        <v-col cols="12" class="mt-10">
          <HomeCardNotifications />
        </v-col>

        <v-col cols="12">
          <HomeListLinkPosts />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Sheet from "@/components/layout/budget/Sheet";
import BillDescription from "@/components/layout/budget/BillDescription";
import MealCards from "@/components/layout/budget/MealCards";
import DoughnutChart from "@/components/layout/budget/DoughnutChart";
import MonthlySales from "@/components/layout/budget/MonthlySales";
import HomeCardNotifications from "@/components/layout/home/HomeCardNotifications";
import HomeListLinkPosts from "@/components/layout/home/HomeListLinkPosts";

export default {
  components: {
    Sheet,
    BillDescription,
    MealCards,
    DoughnutChart,
    MonthlySales,
    HomeCardNotifications,
    HomeListLinkPosts
  },
  data() {
    return {
      backgroundColors: [
        "#FCFCBC",
        "#FFBF1A",
        "#14E233",
        "#A0A4DC",
        "#DCC8B1",
        "#F4F4F4"
      ],
      chartData: [37, 20, 18, 9, 6, 11],
      labels: ["損益", "人件費 ", "食材費", "PF手数料", "家賃", "その他"]
    };
  }
};
</script>
<style scoped>
.section {
  color: #8da5bd;
  margin: 0;
}
.btn {
  height: 36px;
  width: 248px;
}
.section-font-color {
  color: #747474;
}
</style>
