<template>
  <v-container v-if="pageLoading" class="bg-color-theme px-3">
    <v-skeleton-loader type="list-item-two-line, card"></v-skeleton-loader>
  </v-container>
  <v-container v-else class="bg-color-theme">
    <v-card class="mt-2">
      <v-card-title class="justify-center font-18px fw-500 text-333">
        {{ getBusinessYears.start_date | formatDate("YYYY") }}年度
      </v-card-title>
      <v-card-subtitle class="text-center text-87 font-14px mb-0">
        {{ getBusinessYears.start_date | formatDate("YYYY/MM") }}
        ~
        {{ getBusinessYears.end_date | formatDate("YYYY/MM") }}
      </v-card-subtitle>
      <div class="border-after-date"></div>
      <div
        class="d-flex justify-center align-center mt-5 light-yellow mx-3 height-81"
      >
        <div class="font-12px fw-500">
          <v-avatar color="white"><span>参考値</span></v-avatar>
        </div>
        <div class="text-center ml-3">
          <div class="mb-0 font-15px">
            前年度売上実績
          </div>
          <div class="font-25px fw-500 mt-n2">
            {{ getGoalLastAnnualSale | formatMoney }}
          </div>
        </div>
      </div>
      <div class="ml-3 mt-5 font-13px text-555">
        ① 年次売上目標を入力して下さい。
      </div>
      <validation-observer :ref="'observer'" v-slot="{ invalid }">
        <v-form
          @submit.prevent="submit"
          lazy-validation
          autocomplete="off"
          ref="form"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="enter_half_width_amount"
            name=""
          >
            <InputCurrency
              background-color="#EAEDF0"
              dense
              solo
              :height="62"
              @input="fillMonthlyGoalByDistributionFactors"
              v-model="annualGoal.value"
              class="annual-goal-field mx-3 mt-3 pb-4"
              :error-messages="errors"
            >
            </InputCurrency>
          </validation-provider>
          <div class="d-flex justify-end mx-3 mt-n9">
            <v-btn text small class="text-888" @click="resetForm"
              >クリア
              <v-icon right dark x-small>$reload </v-icon>
            </v-btn>
          </div>
          <div class="d-flex justify-center mt-n1">
            <v-icon size="12">$grayDownArrowClosed</v-icon>
          </div>
          <div class="d-flex justify-space-between mx-3 mt-5">
            <div class="font-13px text-555">
              ② 月次売上目標を調整して更新して下さい。
            </div>
            <v-icon
              @click="helpModal('GoalsSettingModal')"
              size="12"
              class="mt-1"
              >$infoIconGrey</v-icon
            >
          </div>
          <div class="business-days-table mt-5 mx-3">
            <v-simple-table>
              <thead>
                <tr>
                  <th></th>
                  <th class="text-center">月次目標</th>
                  <th class="text-center">実績売上<br />達成率</th>
                </tr>
              </thead>
              <tbody v-for="(goalYears, j) in getGoalByYear" :key="j">
                <tr v-for="(item, k) in goalYears" :key="k">
                  <td class="fw-500 font-14px" style="width:20%">
                    {{ item.month }}
                  </td>
                  <td>
                    <v-row align="center">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="enter_half_width_amount"
                        name=""
                      >
                        <InputCurrency
                          background-color="#F5F5F5"
                          dense
                          solo
                          :error-messages="errors"
                          v-model.number="item.value"
                          :class="true ? 'blue-text' : 'gray-text'"
                          placeholder="00"
                          class="monthly-goal-field pt-6"
                          @keyup="calculateAnnualGoal"
                        >
                        </InputCurrency>
                      </validation-provider>
                    </v-row>
                  </td>
                  <td style="width:33%;" class="text-center font-14px">
                    <div>
                      {{ priceFormat(item.sales) }}
                    </div>
                    <div
                      v-if="getAchievementRate(item.value, item.sales) > 0"
                      :class="
                        getAchievementRate(item.value, item.sales) >= 100
                          ? 'green-text'
                          : 'red-text'
                      "
                    >
                      {{
                        getAchievementRate(item.value, item.sales).toFixed(1)
                      }}% 達成
                    </div>
                    <div v-else class="text-777">
                      {{
                        getAchievementRate(item.value, item.sales).toFixed(1)
                      }}% 達成
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <div class="mx-3 py-6">
            <v-btn
              color="primary"
              :loading="getApiProcessingStatus"
              block
              class=" font-14px fw-700"
              @click="submit"
              :disabled="invalid"
            >
              保存する
            </v-btn>
          </div>
        </v-form>
      </validation-observer>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { debounce } from "debounce";
import dayjs from "@/plugins/dayjs";
import InputCurrency from "@/components/ui/InputCurrency";
export default {
  name: "GoalSetting",
  components: { InputCurrency },
  data() {
    return {
      pageLoading: true,
      disabled: false,
      invalid: null,
      currYear: "",
      currMonth: "",
      annualGoal: {
        value: 0,
        goal: "¥0"
      },
      sales: [],
      distributionFactors: [
        0.0813,
        0.065,
        0.0976,
        0.0976,
        0.0813,
        0.0813,
        0.0813,
        0.065,
        0.0813,
        0.0813,
        0.0813,
        0.105
      ]
    };
  },
  created() {
    this.currYear = dayjs().format("YYYY");
    this.currMonth = dayjs().format("MM");

    let this_this = this;
    this.$root.$off("shopChanged");
    this.$root.$on("shopChanged", function() {
      this_this.getDataFromApi();
    });
    this.getDataFromApi();
  },
  computed: {
    ...mapGetters([
      "getGoalByYear",
      "getBusinessYears",
      "getGoalSales",
      "getGoalLastAnnualSale",
      "getApiProcessingStatus"
    ])
  },
  methods: {
    helpModal(component) {
      this.$store.commit("showModal", { component });
    },

    resetForm() {
      this.annualGoal.value = 0;
      for (let gaolYears in this.getGoalByYear) {
        this.getGoalByYear[gaolYears].forEach(goal => {
          goal.value = 0;
        });
      }
    },

    getDataFromApi() {
      this.pageLoading = true;
      let data = {
        shop_id: this.$store.getters.getFirstShopObject.id
      };

      this.$store.dispatch("GOAL_GET_BY_YEAR", data).finally(() => {
        this.pageLoading = false;
        this.annualGoal.value = this.getGoalSales;
      });
    },

    getAchievementRate(goal, sale) {
      if (goal === 0 || sale === 0) return 0;

      let achievementRate = (sale / goal) * 100;
      return achievementRate;
    },

    calculateAnnualGoal: debounce(function() {
      let totalValue = 0;
      for (let gaolYears in this.getGoalByYear) {
        totalValue += this.getGoalByYear[gaolYears].reduce(
          (a, b) => a + b.value,
          0
        );
      }
      this.annualGoal.value = parseInt(totalValue.toFixed(0));
    }, 500),

    fillMonthlyGoalByDistributionFactors: debounce(function() {
      let totalGoal = 0;

      for (let gaolYears in this.getGoalByYear) {
        this.getGoalByYear[gaolYears].forEach(goal => {
          let month = this.formatDate(goal.date, "M");
          let distributedValue =
            this.annualGoal.value * this.distributionFactors[month - 1];
          //goal.value = parseInt(distributedValue.toFixed(0));
          goal.value = distributedValue
            ? parseInt(distributedValue.toFixed(0))
            : 0;
          totalGoal += distributedValue
            ? parseInt(distributedValue.toFixed(0))
            : 0;
        });
      }

      this.annualGoal.value = parseInt(totalGoal.toFixed(0));
    }, 500),

    submit() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }

        let params = {};
        let shop_id = this.$store.getters.getFirstShopObject.id;
        params.annual_goal = this.annualGoal.value;
        params.goals = this.getGoalByYear;
        this.$store
          .dispatch("GOAL_REGISTER", { params, shop_id })
          .then(response => {
            this.getDataFromApi();
            this.$store.commit("showAlert", {
              text: "編集内容を保存しました。",
              successStatus: true
            });
          });
      });
    }
  }
};
</script>

<style lang="scss" src="./style.scss"></style>
